import { render, staticRenderFns } from "./scenicDetails.vue?vue&type=template&id=0ac0d5d7&scoped=true&"
import script from "./scenicDetails.vue?vue&type=script&lang=js&"
export * from "./scenicDetails.vue?vue&type=script&lang=js&"
import style0 from "./scenicDetails.vue?vue&type=style&index=0&id=0ac0d5d7&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ac0d5d7",
  null
  
)

export default component.exports